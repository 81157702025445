import * as React from 'react';

function MapIcon({ fillColor, ...props }) {
    return (
        <svg width={20} height={21} viewBox="0 0 20 21" {...props}>
            <title>Map Icon</title>
            <g fill="none" fillRule="evenodd">
                <path
                    d="M2 0L0 18.212l18 1.976 2-18.212L2 0zm15.976 8.353l-7.788-3.059 1.177-2.941 7.176.8-.565 5.2zM8.494 9.506L9.906 6l2.376.941-1.435 3.553-2.353-.988zm-.47 1.223l4.8 1.977-1.318 3.2-4.777-1.953 1.295-3.224zm8.235 7.953l-10.941-1.2 1.13-2.8 9.81 4zm-4.024-2.494l1.318-3.176 3.741 1.553-.4 3.553-4.659-1.93zm-.659-5.412l1.436-3.552 4.894 1.929-.447 4.07-5.883-2.447zm-1.552-8.588L8.988 4.8l-5.93-2.33.118-1.058 6.848.776zm-7.06 1.106L8.707 5.53l-3.177 7.93L2 12.024l.965-8.73zm-1.058 9.53l3.318 1.364-1.271 3.153-2.518-.282.47-4.235z"
                    fill={fillColor || '#C6D2D4'}
                />
                <path d="M-2-2h24v24H-2z" />
            </g>
        </svg>
    );
}

export default MapIcon;

import { combineReducers } from 'redux';

import mapData from './mapData';
import userData from './userData';

const rootReducer = combineReducers({
    mapData,
    userData,
});

export default rootReducer;

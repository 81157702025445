import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import Reducers from './redux/reducers';
import '../../shared/normalize.css';
import i18n from '../../i18n'; // eslint-disable-line no-unused-vars
import App from './app';

import addElectronListener from '../../shared/electronService';
import { localStorageService } from '../../utils/localStorageService';

localStorageService.generateSessionId();

const middlewares = [thunk];
if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line global-require
    const { logger } = require('redux-logger');
    middlewares.push(logger);
}

const store = createStore(Reducers, applyMiddleware(...middlewares));

addElectronListener(store);

window.addEventListener('error', (event) => {
    const errorMessageDiv = document.createElement('div');
    errorMessageDiv.innerText = `Message: ${event.message} \n \n
    Stack: ${event.error.stack}`;
    document.body.appendChild(errorMessageDiv);
});

ReactDOM.render(
    <Provider store={store}>
        <React.StrictMode>
            <App />
        </React.StrictMode>
    </Provider>,
    document.getElementById('root'),
);

export const mapStyles = [
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.fill',
        stylers: [
            {
                gamma: '1.00',
            },
            {
                weight: '4.30',
            },
            {
                visibility: 'on',
            },
            {
                color: '#ffffff',
            },
        ],
    },
    {
        featureType: 'administrative.locality',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.fill',
        stylers: [
            {
                visibility: 'on',
            },
            {
                weight: '9.26',
            },
            {
                color: '#000000',
            },
        ],
    },
    {
        featureType: 'landscape',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#303030',
            },
            {
                saturation: '0',
            },
        ],
    },
    {
        featureType: 'landscape.man_made',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ff0000',
            },
        ],
    },
    {
        featureType: 'landscape.natural.terrain',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#202020',
            },
        ],
    },
    {
        featureType: 'poi',
        elementType: 'all',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.business',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    { featureType: 'poi.business',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'all',
        stylers: [
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'poi.school',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
    { featureType: 'poi.school',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#858585',
            },
            {
                saturation: '0',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'on',
            },
            {
                lightness: '-100',
            },
            {
                gamma: '0.00',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff',
            },
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road',
        elementType: 'labels.text.stroke',
        stylers: [
            {
                visibility: 'off',
            },
            {
                weight: '0.01',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'geometry.fill',
        stylers: [
            {
                color: '#87622B',
            },
        ],
    },
    {
        featureType: 'road.highway',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.highway.controlled_access',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.arterial',
        elementType: 'labels',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'all',
        stylers: [
            {
                color: '#4e4e4e',
            },
            {
                visibility: 'simplified',
            },
        ],
    },
    {
        featureType: 'road.local',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    }, +{
        featureType: 'transit',
        elementType: 'all',
        stylers: [
            {
                visibility: 'on',
            },
            {
                color: '#535353',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#da5959',
            },
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'transit',
        elementType: 'labels.icon',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'transit.line',
        elementType: 'geometry.stroke',
        stylers: [
            {
                color: '#a16c6c',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'all',
        stylers: [
            {
                color: '#007f6d',
            },
            {
                saturation: '0',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'geometry.stroke',
        stylers: [
            {
                visibility: 'off',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text',
        stylers: [
            {
                visibility: 'on',
            },
        ],
    },
    {
        featureType: 'water',
        elementType: 'labels.text.fill',
        stylers: [
            {
                color: '#ffffff',
            },
        ],
    },
];

function latLng2Point(latLng, map) {
    const topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
    const bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
    // eslint-disable-next-line no-restricted-properties
    const scale = Math.pow(2, map.getZoom());
    const worldPoint = map.getProjection().fromLatLngToPoint(latLng);
    // eslint-disable-next-line no-undef
    return new google.maps.Point(
        (worldPoint.x - bottomLeft.x) * scale, (worldPoint.y - topRight.y) * scale,
    );
}

function point2LatLng(point, map) {
    const topRight = map.getProjection().fromLatLngToPoint(map.getBounds().getNorthEast());
    const bottomLeft = map.getProjection().fromLatLngToPoint(map.getBounds().getSouthWest());
    // eslint-disable-next-line no-restricted-properties
    const scale = Math.pow(2, map.getZoom());
    // eslint-disable-next-line no-undef
    const worldPoint = new google.maps.Point(
        point.x / scale + bottomLeft.x, point.y / scale + topRight.y,
    );
    return map.getProjection().fromPointToLatLng(worldPoint);
}

export const getLatLngPixelsOffsetVertical = (position, mapRef, offset = 0) => {
    // eslint-disable-next-line no-undef
    const latLng = new google.maps.LatLng(position.lat, position.lng);
    const pointToShift = latLng2Point(latLng, mapRef);
    pointToShift.y += offset;
    return point2LatLng(pointToShift, mapRef);
};

export const clamp = (min, value, max) => Math.min(Math.max(min, value), max);

export function throttle(func, ms, dropLastApply = false) {
    let isThrottled = false;
    let savedArgs;
    let savedThis;

    function wrapper() {
        if (isThrottled) { // (2)
            // eslint-disable-next-line prefer-rest-params
            savedArgs = arguments;
            savedThis = this;
            return;
        }

        // eslint-disable-next-line prefer-rest-params
        func.apply(this, arguments); // (1)

        isThrottled = true;

        setTimeout(() => {
            isThrottled = false; // (3)
            if (savedArgs && !dropLastApply) {
                wrapper.apply(savedThis, savedArgs);
                // eslint-disable-next-line no-multi-assign
                savedArgs = null;
                savedThis = null;
            }
        }, ms);
    }

    return wrapper;
}

export const SET_MAP_TYPE = 'mapData/SET_MAP_TYPE';
export const MAP_LOADED = 'mapData/MAP_LOADED';
export const SET_INCOME = 'homeApp/userData/SET_INCOME';
export const SET_EQUITY = 'homeApp/userData/SET_EQUITY';
export const SET_EXPENDITURE = 'homeApp/userData/SET_EXPENDITURE';
export const SET_OBJECT_PRICE = 'homeApp/userData/SET_OBJECT_PRICE';
export const SET_STREET = 'homeApp/userData/SET_STREET';
export const SET_OBJECT_LOCATION = 'homeApp/userData/SET_OBJECT_LOCATION';
export const SET_POSTCODE = 'homeApp/userData/SET_POSTCODE';
export const SET_MAP_ZOOM = 'homeApp/userData/SET_MAP_ZOOM';
export const IS_MAP_ZOOMING = 'homeApp/userData/IS_MAP_ZOOMING';

const minEquityOffsetFromTop = 5;
const minMortgageOffsetFromTop = 5;

export const roundNumber = (value, precision = 2) => parseFloat(value.toFixed(`${precision}`));

export const getPercentage = (percentage, value) => roundNumber((percentage * value) / 100);

export const getMortgage = (equity, price) => (
    roundNumber(price - equity > 0 ? price - equity : 0)
);

export const formatPercentage = (portion, value) => (
    roundNumber(value > 0 ? (portion * 100) / value : 0)
);

const MIN_MORTGAGE_OFFSET = 17;
const MAX_MORTGAGE_OFFSET = 53;
export const getMortgageOffset = (mortgage, price) => Math.min(
    Math.max(
        roundNumber(
            formatPercentage(mortgage, price) / 2 + minMortgageOffsetFromTop,
        ),
        MIN_MORTGAGE_OFFSET,
    ),
    MAX_MORTGAGE_OFFSET,
);

const MIN_EQUITY_OFFSET = 7;
const MAX_EQUITY_OFFSET = 53;
export const getEquityOffset = (equity, price) => Math.min(
    Math.max(
        roundNumber(formatPercentage(equity, price) / 2 + minEquityOffsetFromTop),
        MIN_EQUITY_OFFSET,
    ),
    MAX_EQUITY_OFFSET,
);

const MIN_AFFORDABILITY_OFFSET = 17;
const MAX_AFFORDABILITY_OFFSET = 53;
export const getAffordabilityOffset = (totalcost, netIncome) => Math.min(
    Math.max(
        roundNumber(formatPercentage(totalcost, netIncome) / 2),
        MIN_AFFORDABILITY_OFFSET,
    ),
    MAX_AFFORDABILITY_OFFSET,
);

export const getFirstMortgage = (objectPrice, equity) => {
    const firstMortgage = getPercentage(65, objectPrice);
    const diff = objectPrice - equity;
    return roundNumber(firstMortgage < diff ? firstMortgage : diff);
};

export const getSecondMortgage = (objectPrice, equity) => {
    const secondMortgage = objectPrice - equity - getFirstMortgage(objectPrice, equity);
    return secondMortgage > 0 ? roundNumber(secondMortgage) : 0;
};

export const calculateAmortization = (
    secondMortgage, years,
) => (years > 0 ? roundNumber(secondMortgage / years) : 0);

export const calculateInterest = (
    mortgage, interest,
) => roundNumber((mortgage * interest) / 100);

export const calculateAdditionalCosts = (
    objectPrice, additionalCosts,
) => roundNumber((objectPrice * additionalCosts) / 100);

export const calculateAccumInterest = (tokens) => (
    tokens.reduce((accum, token) => accum + calculateInterest(token.value, token.interest), 0)
);

export const getMonthlyValue = (value) => Math.round(value / 12);

export const selectorCalculateTotalCosts = (state, optionalInterest, optionalAmortization) => {
    const {
        interest,
        additionalCost,
        objectPrice,
        equity,
        years,
    } = state;

    const mortgageValue = getMortgage(equity, objectPrice);
    const secondMortgage = getSecondMortgage(objectPrice, equity);
    const interestValue = (typeof optionalInterest !== 'undefined') ? optionalInterest : calculateInterest(mortgageValue, interest);
    const additionalCostsValue = calculateAdditionalCosts(
        objectPrice,
        additionalCost,
    );

    const amortizationValue = (typeof optionalAmortization !== 'undefined') ? optionalAmortization : calculateAmortization(secondMortgage, years);
    return roundNumber(interestValue + additionalCostsValue + amortizationValue);
};

export const roundFloorHundreds = (value, roundToNumber = 500) => (
    Math.floor(value / roundToNumber) * roundToNumber
);

export const roundCeilHundreds = (value, roundToNumber = 500) => (
    Math.ceil(value / roundToNumber) * roundToNumber
);

export const calculateMaxObjectPriceByEquity = (equity) => roundFloorHundreds(equity / 0.2);
// should rewrite to support additionalCost interest and years parameters
const maxObjectPriceByAffordabilityWithSecondMortgage = (
    equity, availableIncome, additionalCost, interest, years,
) => roundFloorHundreds(
    (-equity - availableIncome * years - equity * years * interest)
        / (-years * additionalCost - interest * years - 0.35),
);

const objectPriceNoSecondMortgage = (
    equity, availableIncome, additionalCost, interest,
) => roundFloorHundreds(
    (equity * interest + availableIncome) / (additionalCost + interest),
);

const calculateMaxObjectPriceByAffordability = (
    equity, availableIncome, additionalCost, interest, years,
) => {
    const objectPriceWithSecondMortgage = maxObjectPriceByAffordabilityWithSecondMortgage(
        equity, availableIncome, additionalCost, interest, years,
    );
    if (objectPriceWithSecondMortgage * 0.65 < objectPriceWithSecondMortgage - equity) {
        return objectPriceWithSecondMortgage;
    }

    return roundCeilHundreds(
        objectPriceNoSecondMortgage(
            equity, availableIncome, additionalCost, interest,
        ),
    );
};

const equityLimitByAffordabilityNoSecondMortgage = (
    price, totalCost, additionalCost, interest,
) => (100 * (-totalCost + (additionalCost * price) / 100 + (interest * price) / 100)) / interest;

const equityLimitByAffordabilityWithSecondMotgage = (
    price, totalCost, additionalCost, interest, years,
) => ((-totalCost
    + (additionalCost * price) / 100
    + (0.35 * price) / years
    + (interest * price) / 100)) * ((100 * years) / (100 + years * interest));

function getEquityLimitByMortgageSize(objectPrice) {
    return roundCeilHundreds(objectPrice * 0.2);
}

function getEquityLimitByAffordability(
    objectPrice, availableIncome, additionalCost, interest, years,
) {
    const affordabilityWithSecondMortgage = equityLimitByAffordabilityWithSecondMotgage(
        objectPrice, availableIncome * 0.4, additionalCost, interest, years,
    );
    if (affordabilityWithSecondMortgage < objectPrice - objectPrice * 0.65) {
        return roundCeilHundreds(affordabilityWithSecondMortgage);
    }

    return roundCeilHundreds(
        equityLimitByAffordabilityNoSecondMortgage(
            objectPrice, availableIncome * 0.4, additionalCost, interest,
        ),
    );
}

export function getMaxObjectPrice(equity, availableIncome, additionalCost, interest, years) {
    return Math.min(
        calculateMaxObjectPriceByEquity(equity),
        calculateMaxObjectPriceByAffordability(
            equity, availableIncome * 0.4, additionalCost / 100, interest / 100, years,
        ),
    );
}

export function getRequiredEquity(objectPrice, availableIncome, additionalCost, interest, years) {
    return Math.max(
        getEquityLimitByAffordability(
            objectPrice, availableIncome, additionalCost, interest, years,
        ),
        getEquityLimitByMortgageSize(objectPrice),
    );
}

export function getRequiredIncome(totalCosts) {
    return roundCeilHundreds(totalCosts / 0.4);
}

export const selectCurrentTokenSum = (activatedTokens) => (
    activatedTokens.reduce((acc, curr) => curr.value + acc, 0)
);

const stepToChange = 25;

let step = 0;

export const getActiveInputDelta = (deltaY) => (value, increment) => {
    const deltaThreshold = 45;
    const minDeltaMultiplier = 1;
    const deltaMultiplier = Math.round(
        Math.max(Math.abs(deltaY / deltaThreshold), minDeltaMultiplier),
    );
    if (deltaMultiplier > 1) {
        step += deltaMultiplier * 10;
    } else {
        step += 1;
    }
    if (step >= stepToChange) {
        step = 0;
        return deltaY > 0
            ? value + increment * deltaMultiplier
            : value - increment * deltaMultiplier;
    }
    return value;
};

export const getActiveInputWithoutMultiplier = (deltaY) => (value, increment) => (
    deltaY > 0 ? value + increment : value - increment
);

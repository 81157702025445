import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { translationsMap } from './translations';

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            'en-GB': {
                translations: {
                    ...translationsMap,
                },
            },
            'de-CH': {
                translations: {
                    'BELEHNUNG & TRAGBARKEIT': 'BELEHNUNG & TRAGBARKEIT',
                    HYPOTHEKENMIX: 'HYPOTHEKENMIX',
                    CURRENCY: 'CHF',
                    EXPENDITURE: 'AUSGABEN',
                    'LOAN-TO-VALUE RATIO': 'BELEHNUNG',
                    EQUITY: 'EIGENKAPITAL',
                    AFFORDABILITY: 'TRAGBARKEIT',
                    YEARLY: 'JÄHRLICH',
                    'HALF-YEARLY': 'HALBJÄHRLICH',
                    QUARTERLY: 'QUARTALSWEISE',
                    MONTHLY: 'MONATLICH',
                    'OBJECT PRICE': 'OBJEKTKOSTEN',
                    MORTGAGE: 'HYPOTHEK',
                    'AVAILABLE INCOME': 'VERFÜGBARES EINKOMMEN',
                    INCOME: 'EINKOMMEN',
                    'ADDITIONAL COSTS': 'NEBENKOSTEN',
                    INTEREST: 'ZINS',
                    YEARS: 'JAHRE',
                    AMORTIZATION: 'AMORTISATION',
                    'MAX. OBJECT PRICE': 'MAX. OBJEKTKOSTEN',
                    'REQUIRED EQUITY': 'BENÖTIGTES EIGENKAPITAL',
                    'REQUIRED INCOME': 'BENÖTIGTES  EINKOMMEN',
                    'ZOOM OUT MAP': 'Karte verkleinern',
                    'SATELITE VIEW': 'Satellit',
                    'MAP VIEW': 'Karte',
                    'DESIRED OBJECT': 'Wunschobjekt',
                    'FINANCIAL SITUATION': 'Finanzsituation',
                    STREET: 'STRASSE',
                    LOCATION: 'ORT',
                    'PURCHASE PRICE': 'KAUFPREIS',
                    REPAYMENT: 'BELASTUNGEN',
                    'PER YEAR': 'pro Jahr',
                    'PER MONTH': 'pro Monat',
                    'MANDATORY AMORTIZATION': 'Pflichtamortisation',
                    'INTEREST COSTS': 'ZINSKOSTEN',
                    'INCL.CALC.ADDITIONAL COSTS': 'inkl. kalk. Nebenkosten',
                    'THE NUMBERS REFER TO THE START TIME': 'die Zahlen beziehen sich auf den Startzeitpunkt',
                    TRANCHE: 'Tranche',
                    TERMS: 'Laufzeiten',
                    TERM: 'Laufzeit',
                    FIXED: 'FIX',
                    VARIABLE: 'VARIABLE',
                    SARON: 'SARON',
                    'FIXED-RATE MORTGAGE': 'FESTHYPOTHEK',
                    'SARON MORTGAGE': 'SARON HYPOTHEK',
                    'VARIABLE MORTGAGE': 'VARIABLE HYPOTHEK',
                    MISSING: 'FEHLEND',
                    INDIRECT: 'INDIREKT',
                    DIRECT: 'DIREKT',
                    ACCOUNT: 'KONTO',
                    RATE: 'RATE',
                    'TOTAL YEAR': 'TOTAL JAHR',
                    PERIODICITY: 'PERIODIZITÄT',
                    REQUIRED: 'SOLL',
                    TOTAL: 'TOTAL',
                },
            },
        },
        fallbackLng: 'de-CH',
        detection: {
            order: ['querystring'],
        },
        // have a common namespace used around the full app
        ns: ['translations'],
        defaultNS: 'translations',

        keySeparator: false, // we use content as keys

        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ',',
        },

        react: {
            wait: true,
        },
    });

export default i18n;

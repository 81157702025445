import React, { useEffect, useState } from 'react';
import cs from 'classnames';

import { connect } from 'react-redux';
import style from './homeContainer.css';
import Map from './map';
import MapControls from './mapControls';
import hypiIcon from './images/hypi_pin.svg';
import Marker from './map/components/Marker';
import LocationMarker from './LocationMarker';
import UserDataPanel from './userDataPanel';
import { setMapZoom } from './redux/actions/map.actions';
import { useWindowsState, WindowsStateName } from '../../shared/hooks';

let passRefZoom;
function App({ onSetMapZoom, isZooming, state }) {
    const [isMapScaled, scaleMap] = useState(false);
    const { sendWindowsState } = useWindowsState(WindowsStateName.HomeApp, state);

    useEffect(() => {
        sendWindowsState();
    }, [state]);

    return (
        <div
            className={style.app}
            onWheel={(e) => {
                onSetMapZoom(e.deltaY, passRefZoom);
            }}
        >
            <div className={style.marginBlock}>
                <UserDataPanel isMapScaled={isMapScaled} />
            </div>
            <div className={style.centeredBlock}>
                <div className={cs(style.mapContainer, {
                    [style.bigMask]: !isMapScaled,
                    [style.smallMask]: isMapScaled,
                })}
                >
                    <Map />
                    {!isZooming && (
                        <Marker
                            position={{ lat: 47.388037, lng: 8.178726 }}
                            icon={hypiIcon}
                        />
                    )}
                    <LocationMarker isMapScaled={isMapScaled} />
                </div>
            </div>
            <div className={style.controlContainer}>
                <div className={cs(style.controlMargin, {
                    [style.smallMargin]: isMapScaled,
                })}
                />
                <MapControls
                    height={360}
                    topOffset={0}
                    isMapScaled={isMapScaled}
                    scaleMap={scaleMap}
                />
            </div>
            <div className={style.marginBlock} />
        </div>
    );
}

const mapDispatchToProps = (dispatch) => ({
    onSetMapZoom: (delta, setZooming) => dispatch(setMapZoom(delta, setZooming)),
});

const mapStateToProps = (state) => ({
    isZooming: state.mapData.isZooming,
    state,
});
export default connect(mapStateToProps, mapDispatchToProps)(App);

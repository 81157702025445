export const SET_INCOME = 'affordability/SET_INCOME';
export const SET_EQUITY = 'affordability/SET_EQUITY';
export const SET_EXPENDITURE = 'affordability/SET_EXPENDITURE';
export const SET_OBJECT_PRICE = 'affordability/SET_OBJECT_PRICE';
export const SET_ACTIVE_INPUT = 'affordability/SET_ACTIVE_INPUT';
export const SET_FIXED_INPUT = 'affordability/SET_FIXED_INPUT';
export const SET_ADDITIONAL_COST = 'affordability/SET_ADDITIONAL_COST';
export const SET_INTEREST = 'affordability/SET_INTEREST';
export const SET_YEARS = 'affordability/SET_YEARS';
export const INIT_USER_VALUES = 'affordability/INIT_USER_VALUES';
export const SET_MANDATORY_AMORTIZATION = 'mixMortgage/SET_MANDATORY_AMORTIZATION';
export const SET_MIX_EQUITY = 'mixMortgage/SET_MIX_EQUITY';
export const INIT_MIX_USER_VALUES = 'mixMortgage/INIT_MIX_USER_VALUES';
export const SET_MIX_OBJECT_PRICE = 'mixMortgage/SET_MIX_OBJECT_PRICE';
export const CHANGE_TOKEN_TERM = 'mixMortgage/CHANGE_TOKEN_YEARS';
export const CHANGE_TOKEN_TRANCHE = 'mixMortgage/CHANGE_TOKEN_TRANCHE';
export const CHANGE_TOKEN_INTEREST = 'mixMortgage/CHANGE_TOKEN_INTEREST';
export const ADD_TOKEN = 'mixMortgage/ADD_TOKEN';
export const ACTIVATE_TOKEN = 'mixMortgage/ACTIVATE_TOKEN';
export const SET_ACTIVE_TOKEN_ID = 'mixMortgage/SET_ACTIVE_TOKEN_ID';
export const UPDATE_TOKEN_POSITION = 'mixMortgage/UPDATE_TOKEN_POSITION';
export const RESET_INPUTS = 'mixMortgage/RESET_INPUTS';
export const ACTIVATE_INPUT = 'mixMortgage/ACTIVATE_INPUT';
export const MOVE_OUTSIDE_TOKEN = 'mixMortgage/MOVE_OUTSIDE_TOKEN';
export const REMOVE_TOKEN = 'mixMortgage/REMOVE_TOKEN';
export const SET_TOKEN_TYPE_AMORTIZATION = 'amortizationPage/SET_TOKEN_TYPE';
export const SET_ACCOUNT_TYPE_AMORTIZATION = 'amortizationPage/SET_ACCOUNT_TYPE';
export const UPDATE_TOKEN_POSITION_AMORTIZATION = 'amortizationPage/UPDATE_TOKEN_POSITION_AMORTIZATION';
export const CHANGE_TOKEN_VALUE_AMORTIZATION = 'amortizationPage/CHANGE_TOKEN_VALUE_AMORTIZATION';
export const CHANGE_TOKEN_PERIOD_AMORTIZATION = 'amortizationPage/CHANGE_TOKEN_PERIOD_AMORTIZATION';
export const ADD_TOKEN_AMORTIZATION = 'amortizationPage/ADD_TOKEN_AMORTIZATION';
export const ACTIVATE_TOKEN_AMORTIZATION = 'amortizationPage/ACTIVATE_TOKEN_AMORTIZATION';
export const REMOVE_TOKEN_AMORTIZATION = 'amortizationPage/REMOVE_TOKEN_AMORTIZATION';
export const ACTIVATE_INPUT_AMORTIZATION = 'amortizationPage/ACTIVATE_INPUT_AMORTIZATION';
export const SET_ACTIVE_TOKEN_ID_AMORTIZATION = 'amortizationPage/SET_ACTIVE_TOKEN_ID_AMORTIZATION';
export const RESET_INPUTS_AMORTIZATION = 'amortizationPage/RESET_INPUTS_AMORTIZATION';
export const SET_OBJECT_PRICE_AMORTIZATION = 'amortizationPage/SET_OBJECT_PRICE_AMORTIZATION';
export const SET_EQUITY_AMORTIZATION = 'amortizationPage/SET_EQUITY_AMORTIZATION';
export const CHANGE_COMMON_TERM = 'amortizationPage/CHANGE_COMMON_TERM';
export const CHANGE_ANNUAL_AMORTIZATION = 'amortizationPage/CHANGE_ANNUAL_AMORTIZATION';
export const INIT_USER_VALUES_AMORTIZATION = 'amortizationPage/INIT_USER_VALUES_AMORTIZATION';
export const MOVE_OUTSIDE_TOKEN_AMORTIZATION = 'amortizationPage/MOVE_OUTSIDE_TOKEN_AMORTIZATION';
export const RESET_TOKENS_AMORTIZATION = 'amortizationPage/RESET_TOKENS';

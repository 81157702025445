export const translationsMap = {
    'BELEHNUNG & TRAGBARKEIT': 'LOAN TO VALUE RATIO & AFFORDABILITY',
    HYPOTHEKENMIX: 'MORTGAGE MIX',
    CURRENCY: 'GBP',
    EXPENDITURE: 'EXPENDITURE',
    EQUITY: 'EQUITY',
    'LOAN-TO-VALUE RATIO': 'LOAN TO VALUE RATIO',
    AFFORDABILITY: 'AFFORDABILITY',
    YEARLY: 'YEARLY',
    'OBJECT PRICE': 'OBJECT PRICE',
    MORTGAGE: 'MORTGAGE',
    'AVAILABLE INCOME': 'AVAILABLE INCOME',
    INCOME: 'INCOME',
    'ADDITIONAL COSTS': 'ADDITIONAL COSTS',
    INTEREST: 'INTEREST',
    AMORTIZATION: 'AMORTIZATION',
    YEARS: 'YEARS',
    'HALF-YEARLY': 'HALF-YEARLY',
    QUARTERLY: 'QUARTERLY',
    MONTHLY: 'MONTHLY',
    'MAX. OBJECT PRICE': 'MAX. OBJECT PRICE',
    'REQUIRED EQUITY': 'REQUIRED EQUITY',
    'REQUIRED INCOME': 'REQUIRED INCOME',
    'ZOOM OUT MAP': 'Karte verkleinern',
    'SATELITE VIEW': 'Satellite',
    'MAP VIEW': 'Map',
    'DESIRED OBJECT': 'Desired object',
    'FINANCIAL SITUATION': 'Financial situation',
    STREET: 'STREET',
    LOCATION: 'LOCATION',
    'PURCHASE PRICE': 'PURCHASE PRICE',
    REPAYMENT: 'REPAYMENT',
    'PER YEAR': 'per Year',
    'PER MONTH': 'per Month',
    'MANDATORY AMORTIZATION': 'Mandatory amortization',
    'INTEREST COSTS': 'INTEREST COSTS',
    'INCL.CALC.ADDITIONAL COSTS': 'INCL.CALC.ADDITIONAL COSTS',
    'THE NUMBERS REFER TO THE START TIME': 'THE NUMBERS REFER TO THE START TIME',
    TRANCHE: 'Tranche',
    TERMS: 'Terms',
    TERM: 'Term',
    FIXED: 'FIXED',
    VARIABLE: 'VARIABLE',
    SARON: 'SARON',
    'FIXED-RATE MORTGAGE': 'FIXED-RATE MORTGAGE',
    'SARON MORTGAGE': 'SARON MORTGAGE',
    'VARIABLE MORTGAGE': 'VARIABLE MORTGAGE',
    MISSING: 'MISSING',
    INDIRECT: 'INDIRECT',
    DIRECT: 'DIRECT',
    ACCOUNT: 'ACCOUNT',
    PERIODICITY: 'PERIODICITY',
    RATE: 'RATE',
    'TOTAL YEAR': 'TOTAL YEAR',
    REQUIRED: 'REQUIRED',
    TOTAL: 'TOTAL',
};

import {
    SET_MAP_TYPE,
    MAP_LOADED,
    SET_STREET,
    SET_OBJECT_LOCATION,
    SET_MAP_ZOOM,
    SET_OBJECT_PRICE, IS_MAP_ZOOMING,
} from '../constants';
import { clamp } from '../../utils';
import { localStorageService } from '../../../../utils/localStorageService';

const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const setMapType = (map, mapType) => {
    map.setMapTypeId(mapType);
    return {
        type: SET_MAP_TYPE,
        payload: mapType,
    };
};

export const mapLoaded = () => ({
    type: MAP_LOADED,
});

export const setMapZooming = (isZoomming) => ({
    type: IS_MAP_ZOOMING,
    payload: isZoomming,
});

export const setStreet = (str) => {
    const trimmedStr = str ? str.trimStart() : '';
    const street = capitalizeFirstLetter(trimmedStr);
    const desiredObjects = localStorageService.getDesiredObjects();
    let updatedDesiredObject = { street };
    if (desiredObjects) {
        const [desiredObject] = desiredObjects;
        updatedDesiredObject = {
            ...desiredObject,
            ...updatedDesiredObject,
        };
    }
    localStorageService.setDesiredObjects([updatedDesiredObject]);
    return ({
        type: SET_STREET,
        payload: street,
    });
};

export const setObjectLocation = (str) => (dispatch, getState) => {
    let postcode;
    let objectLocation;
    const trimmedStr = str ? str.trimStart() : '';
    const firstCharacters = trimmedStr.substr(0, trimmedStr.indexOf(' '));
    // check if first characters are number
    if (firstCharacters && typeof +firstCharacters === 'number' && !Number.isNaN(+firstCharacters)) {
        postcode = firstCharacters;
        objectLocation = capitalizeFirstLetter(trimmedStr.substring(firstCharacters.length + 1));
    } else {
        const state = getState();
        postcode = state.userData.postcode;
        objectLocation = capitalizeFirstLetter(trimmedStr);
    }
    const desiredObjects = localStorageService.getDesiredObjects();
    let updatedDesiredObject = { objectLocation };
    if (desiredObjects) {
        const [desiredObject] = desiredObjects;
        updatedDesiredObject = {
            ...desiredObject,
            ...updatedDesiredObject,
        };
    }
    localStorageService.setDesiredObjects([updatedDesiredObject]);
    dispatch({
        type: SET_OBJECT_LOCATION,
        payload: { postcode, objectLocation },
    });
};

export const setObjectPrice = (objectPrice) => {
    const desiredObjects = localStorageService.getDesiredObjects();
    let updatedDesiredObject = { objectPrice };
    if (desiredObjects) {
        const [desiredObject] = desiredObjects;
        updatedDesiredObject = {
            ...desiredObject,
            ...updatedDesiredObject,
        };
    }
    localStorageService.setDesiredObjects([updatedDesiredObject]);
    return ({
        type: SET_OBJECT_PRICE,
        payload: objectPrice,
    });
};

let mapZoomState = 15;

const changeZoom = (delta, dispatch) => {
    /* eslint-disable no-undef */
    const mapZoom = globalMapReference.getZoom();
    const sensitivity = 0.20; // lower less steps

    mapZoomState = delta > 0
        ? mapZoomState + sensitivity
        : mapZoomState - sensitivity;
    mapZoomState = clamp(6, mapZoomState, 22);
    if (Math.abs(mapZoom - mapZoomState) >= 1) {
        // setZooming(true);
        dispatch(setMapZooming(true));
        mapZoomState = Math.round(mapZoomState);
        if (globalMapReference) {
            globalMapReference.setZoom(Math.floor(mapZoomState));
        }
        dispatch({
            type: SET_MAP_ZOOM,
            payload: Math.floor(mapZoomState),
        });
    }
    /* eslint-enable  no-undef */
};

export const setMapZoom = (delta) => (dispatch) => {
    changeZoom(delta, dispatch);
};

import {
    SET_MANDATORY_AMORTIZATION,
    ADD_TOKEN,
    ACTIVATE_TOKEN,
    REMOVE_TOKEN,
    INIT_MIX_USER_VALUES,
    SET_MIX_EQUITY,
    SET_MIX_OBJECT_PRICE,
    CHANGE_TOKEN_TERM,
    CHANGE_TOKEN_TRANCHE,
    CHANGE_TOKEN_INTEREST,
    ACTIVATE_INPUT,
    SET_ACTIVE_TOKEN_ID,
    RESET_INPUTS,
    UPDATE_TOKEN_POSITION, MOVE_OUTSIDE_TOKEN,
} from '../constants';
import {
    getActiveInputDelta,
    getMortgage,
    roundNumber,
} from '../../helpers/calculation';
import { localStorageService } from '../../../../utils/localStorageService';
import { getTrackableTokens } from '../../helpers/utils';

export const setRequiredAmortization = (amount) => {
    const MIN = 0;
    return {
        type: SET_MANDATORY_AMORTIZATION,
        payload: Math.max(roundNumber(amount), MIN),
    };
};

// should generate tokenID to track token and change it on handler
export const addTokenToNearbyList = (token) => ({
    type: ADD_TOKEN,
    payload: { ...token, removed: false },
});

export const activateToken = (token, availableData) => {
    const defaultInterest = 1;
    const defaultTerm = 5;
    const extendToken = {
        ...token,
        interest: defaultInterest,
        value: availableData,
        term: defaultTerm,
    };
    return {
        type: ACTIVATE_TOKEN,
        payload: extendToken,
    };
};

export const updateTokenPosition = (token) => (
    { type: UPDATE_TOKEN_POSITION, payload: token }
);

window.updateTokenPosition = updateTokenPosition;

export const checkAvailableToken = () => (dispatch, getState) => {
    const { equity, objectPrice, nearbyTokens, activatedTokens } = getState().mixMortgage;
    const mortgage = getMortgage(equity, objectPrice);
    const currentTokenSum = activatedTokens.reduce((acc, curr) => curr.value + acc, 0);
    const availableSum = mortgage - currentTokenSum;
    const availableToken = nearbyTokens[0];
    if (availableToken && availableSum > 0) {
        dispatch(activateToken(availableToken, availableSum));
    }
};

const changeInterest = (deltaY, tokenId, value) => {
    const MIN = 0;
    const MAX = 20;
    const getValue = getActiveInputDelta(deltaY);
    const amount = getValue(value, APP_CONFIG.INCREMENTS.INTEREST_MIX_PAGE);

    const interest = Math.min(Math.max(roundNumber(amount, 3), MIN), MAX);
    return {
        type: CHANGE_TOKEN_INTEREST,
        payload: interest,
    };
};

export const changeTokenInterest = (deltaY, tokenId, value) => (dispatch) => {
    dispatch(changeInterest(deltaY, tokenId, value));
    dispatch(checkAvailableToken());
};

export const changeTerm = (deltaY, tokenId, value) => {
    const MIN = 1;
    const MAX = 15;
    const getValue = getActiveInputDelta(deltaY);
    const amount = getValue(value, APP_CONFIG.INCREMENTS.TERM * 5);
    const term = Math.min(Math.max(roundNumber(amount), MIN), MAX);
    return {
        type: CHANGE_TOKEN_TERM,
        payload: term,
    };
};

export const changeTokenTerm = (deltaY, tokenId, value) => (dispatch) => {
    dispatch(changeTerm(deltaY, tokenId, value));
    dispatch(checkAvailableToken());
};

export const changeTranche = (deltaY, tokenId, value) => (dispatch, getState) => {
    const { objectPrice, equity, activatedTokens } = getState().mixMortgage;
    const MIN = 0;
    const mortgage = getMortgage(equity, objectPrice);
    const currentTokenSum = activatedTokens.reduce((acc, curr) => curr.value + acc, 0);
    const availableSum = mortgage - currentTokenSum;
    const MAX = value + availableSum;
    const getValue = getActiveInputDelta(deltaY);
    const amount = getValue(value, APP_CONFIG.INCREMENTS.TRANCHE);
    const tranche = Math.min(Math.max(roundNumber(amount), MIN), MAX);
    dispatch({
        type: CHANGE_TOKEN_TRANCHE,
        payload: tranche,
    });
};

export const changeTokenTranche = (deltaY, tokenId, value) => (dispatch) => {
    dispatch(changeTranche(deltaY, tokenId, value));
    dispatch(checkAvailableToken());
};

export const changeTokenValue = (deltaY, t) => (dispatch, getState) => {
    const { activeInput, activeTokenId, activatedTokens } = getState().mixMortgage;
    if (!activeInput || !activeTokenId) {
        return;
    }
    const activeToken = activatedTokens.find((token) => token.tokenId === activeTokenId);

    const handler = {
        [t('TRANCHE').toLowerCase()]: () => {
            dispatch(changeTokenTranche(deltaY, activeTokenId, activeToken.value));
        },
        [t('TERM').toLowerCase()]: () => {
            dispatch(changeTokenTerm(deltaY, activeTokenId, activeToken.term));
        },
        [t('INTEREST').toLowerCase()]: () => {
            dispatch(changeTokenInterest(deltaY, activeTokenId, activeToken.interest));
        },
    };
    handler[activeInput]();
};

export const addToken = (token) => (dispatch) => {
    dispatch(addTokenToNearbyList(token));
    dispatch(checkAvailableToken());
};

window.addTokenTmp = addToken;

const moveOutsideToken = (tokenId) => ({
    type: MOVE_OUTSIDE_TOKEN,
    payload: tokenId,
});

export const removeToken = (tokenId) => ({
    type: REMOVE_TOKEN,
    payload: tokenId,
});

export const removeTokenCompletely = (tokenId) => (dispatch) => {
    // dispatch(deactivateToken(tokenId));
    dispatch(removeToken(tokenId));
    dispatch(checkAvailableToken());
};

const setActiveTokenId = (tokenId) => ({
    type: SET_ACTIVE_TOKEN_ID,
    payload: tokenId,
});

export const activateInput = (tokenId, input) => (dispatch, getState) => {
    const { activeTokenId } = getState().mixMortgage;
    if (activeTokenId !== tokenId) {
        dispatch({
            type: RESET_INPUTS,
        });
    }
    const inputToActivate = input;
    dispatch(setActiveTokenId(tokenId));
    dispatch({
        type: ACTIVATE_INPUT,
        payload: inputToActivate,
    });
};

const setMixEquity = (amount) => {
    const MIN = 0;
    return {
        type: SET_MIX_EQUITY,
        payload: Math.max(roundNumber(amount), MIN),
    };
};

const setMixObjectPrice = (amount) => {
    const MIN = 0;
    return {
        type: SET_MIX_OBJECT_PRICE,
        payload: Math.max(roundNumber(amount), MIN),
    };
};

export const initUserValues = () => (dispatch) => {
    const desiredObjects = localStorageService.getDesiredObjects();

    let desiredObject;
    if (desiredObjects?.length) {
        [desiredObject] = desiredObjects;
    } else {
        desiredObject = null;
    }

    let objectPrice;
    if (desiredObject?.objectPrice) {
        objectPrice = desiredObject.objectPrice;
    } else {
        objectPrice = APP_CONFIG.FINANCE.DEFAULT_VALUES.objectPrice;
    }

    const equity = localStorageService.getEquity();

    dispatch(setMixEquity(equity != null ? equity : APP_CONFIG.FINANCE.DEFAULT_VALUES.equity));
    dispatch(setMixObjectPrice(objectPrice));

    return {
        type: INIT_MIX_USER_VALUES,
    };
};

export const updateTokensEvent = (tokens) => (dispatch, getState) => {
    const trackableTokens = getTrackableTokens(
        tokens,
        APP_CONFIG.LINKS_TO_SHOW_IN_MENU.HYPOTHEKENMIX,
    );
    // eslint-disable-next-line no-unused-vars
    const { activatedTokens, nearbyTokens, deletedTokens } = getState().mixMortgage;
    const appIds = [];
    activatedTokens.forEach((el) => appIds.push(el.tokenId));
    nearbyTokens.forEach((el) => appIds.push(el.tokenId));
    deletedTokens.forEach((el) => appIds.push(el.tokenId));
    const IdsToUpdate = trackableTokens.map((el) => el && el.tokenId).filter((el) => el);
    trackableTokens.forEach((token) => {
        if (!token || !(token && token.tokenId)) {
            return;
        }
        if (!appIds.includes(token.tokenId)) {
            dispatch(addToken(token));
        }
        if (appIds.includes(token.tokenId)) {
            dispatch(updateTokenPosition(token));
        }
    });

    appIds.forEach((existedTokenId) => {
        if (!IdsToUpdate.includes(existedTokenId)) {
            // removeToOutsideList
            dispatch(moveOutsideToken(existedTokenId));
        }
    });
};
window.updateTokenEvents = updateTokensEvent;

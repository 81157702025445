const trackableTokensMap = {
    [APP_CONFIG.LINKS_TO_SHOW_IN_MENU.AMORTIZATION]: [
        APP_CONFIG.TOKENS.TYPES.directIndirect,
    ],
    [APP_CONFIG.LINKS_TO_SHOW_IN_MENU.HYPOTHEKENMIX]: [
        APP_CONFIG.TOKENS.TYPES.fixed,
        APP_CONFIG.TOKENS.TYPES.variable,
        APP_CONFIG.TOKENS.TYPES.saron,
    ],
};

export const getTrackableTokens = (tokens = [], page) => {
    switch (page) {
        case APP_CONFIG.LINKS_TO_SHOW_IN_MENU.AMORTIZATION:
            return tokens.filter((token) => (
                trackableTokensMap[APP_CONFIG.LINKS_TO_SHOW_IN_MENU.AMORTIZATION]
                    .includes(token?.tokenType)
            ));

        case APP_CONFIG.LINKS_TO_SHOW_IN_MENU.HYPOTHEKENMIX:
            return tokens.filter((token) => (
                trackableTokensMap[APP_CONFIG.LINKS_TO_SHOW_IN_MENU.HYPOTHEKENMIX]
                    .includes(token?.tokenType)
            ));

        default:
            return tokens;
    }
};

export const cloneCalculatorDOM = (elementId) => {
    const element = document.querySelector('.calculatorWrapper');

    const elementClone = element.cloneNode(true);

    elementClone.setAttribute('id', elementId);
    elementClone.style.width = '1100px';
    elementClone.style.height = '750px';

    const btnBack = elementClone.querySelector('.btnBack');
    const title = document.createElement('h1');

    title.innerText = 'Ihre Finanzierungs- und Tragbarkeitsberechnung in CHF';
    title.style.marginBottom = '40px';
    title.style.fontSize = '18px';
    title.style.color = '#4F5458';
    btnBack.parentNode.replaceChild(title, btnBack);

    const paper = elementClone.querySelector('.paperWrapper');

    paper.style.margin = 0;
    paper.style.backgroundColor = '#fff';
    elementClone.querySelectorAll('.textBackground').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.strokeWidth = '16px';
        // eslint-disable-next-line no-param-reassign
        el.style.stroke = '#fff';
    });
    elementClone.querySelectorAll('.globalBtn, .dot').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
    });
    elementClone.querySelectorAll('.icon').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.padding = '16px 8px 10px';
    });

    const btnPanel = elementClone.querySelector('.btnPanel');

    btnPanel.style.display = 'none';

    const valueRatio = element.querySelector('.valueRatioWrapper');
    const valueRatioClone = elementClone.querySelector('.valueRatioWrapper');
    const oldValueRatioCanvas = valueRatio.querySelector('canvas');
    const valueRatioCloneCanvas = valueRatioClone.querySelector('canvas');
    const newValueRatioCanvas = document.createElement('canvas');
    const valueRatioContext = newValueRatioCanvas.getContext('2d');

    const width = 40;
    const height = 480;

    newValueRatioCanvas.width = width;
    newValueRatioCanvas.height = height;
    valueRatioContext.drawImage(oldValueRatioCanvas, 0, 0, width, height);
    valueRatioCloneCanvas.parentNode.replaceChild(newValueRatioCanvas, valueRatioCloneCanvas);

    const affordability = element.querySelector('.affordabilityWrapper');
    const affordabilityClone = elementClone.querySelector('.affordabilityWrapper');
    const oldAffordabilityCanvas = affordability.querySelector('canvas');
    const affordabilityCloneCanvas = affordabilityClone.querySelector('canvas');
    const newAffordabilityCanvas = document.createElement('canvas');
    const affordabilityContext = newAffordabilityCanvas.getContext('2d');

    newAffordabilityCanvas.width = width;
    newAffordabilityCanvas.height = height;
    affordabilityContext.drawImage(oldAffordabilityCanvas, 0, 0, width, height);
    // eslint-disable-next-line max-len
    affordabilityCloneCanvas.parentNode.replaceChild(newAffordabilityCanvas, affordabilityCloneCanvas);

    document.body.appendChild(elementClone);
};

export const cloneMixDOM = (elementId) => {
    const element = document.querySelector('.mixWrapper');

    const elementClone = element.cloneNode(true);

    elementClone.style.width = '1100px';
    elementClone.style.height = '750px';
    elementClone.setAttribute('id', elementId);

    const btnBack = elementClone.querySelector('.btnBack');

    const title = document.createElement('h1');

    title.innerText = 'Übersicht unserer Finanzierungsvorschläge für Sie';
    title.style.width = '550px';
    title.style.margin = '0 0 40px 25px';
    title.style.fontSize = '18px';
    title.style.color = '#4F5458';
    btnBack.parentNode.replaceChild(title, btnBack);

    const page = elementClone.querySelector('.pageWrapper');

    page.style.margin = 0;
    page.style.backgroundColor = '#fff';

    const scale = elementClone.querySelector('.scaleWrapper');

    scale.style.backgroundColor = '#4F5458';

    elementClone.querySelectorAll('.globalBtn, .dot').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
    });

    document.body.appendChild(elementClone);
};

export const cloneAmortizationDOM = (elementId) => {
    const element = document.querySelector('.amortizationWrapper');

    const elementClone = element.cloneNode(true);

    elementClone.style.width = '1100px';
    elementClone.style.height = '750px';
    elementClone.setAttribute('id', elementId);

    const btnBack = elementClone.querySelector('.btnBack');

    const title = document.createElement('h1');

    title.innerText = 'Ihre Amortisation';
    title.style.marginBottom = '40px';
    title.style.fontSize = '18px';
    title.style.color = '#4F5458';
    btnBack.parentNode.replaceChild(title, btnBack);

    const page = elementClone.querySelector('.pageWrapper');

    page.style.margin = 0;
    page.style.backgroundColor = '#fff';

    elementClone.querySelectorAll('.titleWrapper, .valueWrapper').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.color = '#4F5458';
    });

    elementClone.querySelectorAll('.globalBtn, .dot').forEach((el) => {
        // eslint-disable-next-line no-param-reassign
        el.style.display = 'none';
    });

    document.body.appendChild(elementClone);
};

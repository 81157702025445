import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withMap from './hocs/withMap';
import { getLatLngPixelsOffsetVertical } from './utils';
import Marker from './map/components/Marker';

function LocationMarker({ google, map, street, objectLocation, isMapScaled, zoom, postcode }) {
    const defaultPosition = new google.maps.LatLng({ lat: 47.388037, lng: 8.178726 });
    const [offsetMarkerPosition, setMarkerPosition] = useState(defaultPosition);

    useEffect(() => {
        if (isMapScaled && offsetMarkerPosition) {
            google.maps.event.trigger(map, 'resize');

            map.panTo(getLatLngPixelsOffsetVertical({
                lat: offsetMarkerPosition.lat(),
                lng: offsetMarkerPosition.lng(),
            }, map, -250));
        }
        if (!isMapScaled) {
            map.panTo(offsetMarkerPosition);
        }
    }, [
        isMapScaled,
        zoom,
        offsetMarkerPosition.lat(),
        offsetMarkerPosition.lng(),
    ]);

    useEffect(() => {
        if (street && objectLocation) {
            const geocoder = new google.maps.Geocoder();
            const postalCode = !postcode ? '' : `${postcode} `;
            geocoder.geocode({ address: `${street}, ${postalCode}${objectLocation}` }, (results, status) => {
                if (status === 'OK' && results.length) {
                    google.maps.event.trigger(map, 'resize');
                    map.panTo(results[0].geometry.location);
                    setMarkerPosition(results[0].geometry.location);
                }
            });
        }
    }, [
        street,
        objectLocation,
        postcode,
    ]);

    if (offsetMarkerPosition && isMapScaled) {
        return (
            <Marker position={{
                lat: offsetMarkerPosition.lat(),
                lng: offsetMarkerPosition.lng(),
            }}
            />
        );
    }
    return null;
}

const mapStateToProps = (state) => ({
    street: state.userData.street,
    objectLocation: state.userData.objectLocation,
    postcode: state.userData.postcode,
    zoom: state.mapData.zoom,
});

export default connect(mapStateToProps)(withMap(LocationMarker));

export const AMORTIZATION_TOKEN_TYPES = {
    direct: 'DIRECT',
    indirect: 'INDIRECT',
};

export const ACCOUNT_TYPES = {
    konto: '3A Konto',
    lvp: '3A LVP',
};

export const TOKEN_COLORS = {
    [AMORTIZATION_TOKEN_TYPES.direct]: '#37A8A9',
    [AMORTIZATION_TOKEN_TYPES.indirect]: '#79FBFC',
};

export const PERIOD_OPTIONS = ['YEARLY', 'HALF-YEARLY', 'QUARTERLY', 'MONTHLY'];

export const PERIOD_DIVIDERS = {
    yearly: 1,
    monthly: 12,
    quarterly: 4,
    'half-yearly': 2,
};

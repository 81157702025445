const shortid = require('shortid');

export const storageFields = {
    incomeItems: 'incomeItems',
    sessionId: 'sessionId',
    updated: 'updated',
    expenditure: 'expenses',
    equity: 'ownCapital',
    desiredObjects: 'desiredObjects',
    postcode: 'postcode',
};

const setLocalStorageItem = (name, value) => {
    localStorage.setItem(name, value);
    localStorage.setItem(storageFields.updated, Date.now());
};

export const localStorageService = {
    setEquity: (equity) => {
        setLocalStorageItem(storageFields.equity, JSON.stringify(equity));
    },
    setPostcode: (postcode) => {
        setLocalStorageItem(storageFields.postcode, JSON.stringify(postcode));
    },
    setExpenditure: (expenditure) => {
        setLocalStorageItem(storageFields.expenditure, JSON.stringify(expenditure));
    },
    setIncomeItems: (inputs) => {
        setLocalStorageItem(storageFields.incomeItems, JSON.stringify(inputs));
    },
    setDesiredObjects: (desiredObjects) => {
        setLocalStorageItem(storageFields.desiredObjects, JSON.stringify(desiredObjects));
    },
    generateSessionId: () => {
        localStorage.clear();
        setLocalStorageItem(storageFields.sessionId, shortid.generate());
    },
    getExpenditure: () => JSON.parse(localStorage.getItem(storageFields.expenditure)),
    getEquity: () => JSON.parse(localStorage.getItem(storageFields.equity)),
    getIncomeItems: () => JSON.parse(localStorage.getItem(storageFields.incomeItems)),
    getDesiredObjects: () => JSON.parse(localStorage.getItem(storageFields.desiredObjects)),
};

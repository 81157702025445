import {
    SET_TOKEN_TYPE_AMORTIZATION,
    SET_ACCOUNT_TYPE_AMORTIZATION,
    SET_ACTIVE_TOKEN_ID_AMORTIZATION,
    RESET_INPUTS_AMORTIZATION,
    ACTIVATE_INPUT_AMORTIZATION,
    CHANGE_TOKEN_VALUE_AMORTIZATION,
    CHANGE_COMMON_TERM,
    CHANGE_ANNUAL_AMORTIZATION,
    CHANGE_TOKEN_PERIOD_AMORTIZATION,
    SET_EQUITY_AMORTIZATION,
    SET_OBJECT_PRICE_AMORTIZATION,
    INIT_USER_VALUES_AMORTIZATION,
    REMOVE_TOKEN_AMORTIZATION,
    ADD_TOKEN_AMORTIZATION,
    UPDATE_TOKEN_POSITION_AMORTIZATION,
    MOVE_OUTSIDE_TOKEN_AMORTIZATION,
    RESET_TOKENS_AMORTIZATION,
} from '../constants';
import {
    roundNumber,
    getActiveInputDelta,
    getSecondMortgage,
    roundCeilHundreds,
    roundFloorHundreds,
} from '../../helpers/calculation';
import { localStorageService } from '../../../../utils/localStorageService';
import {
    AMORTIZATION_TOKEN_TYPES,
    PERIOD_DIVIDERS,
    PERIOD_OPTIONS,
} from '../../amortizationPage/constants';
import { clamp } from '../../../../homeApp/src/utils';
import { getTrackableTokens } from '../../helpers/utils';

export const setAmortizationEquity = (amount) => {
    const MIN = 0;
    return {
        type: SET_EQUITY_AMORTIZATION,
        payload: Math.max(roundNumber(amount), MIN),
    };
};

export const setAmortizationObjectPrice = (amount) => {
    const MIN = 0;
    return {
        type: SET_OBJECT_PRICE_AMORTIZATION,
        payload: Math.max(roundNumber(amount), MIN),
    };
};

const getAmortizationTokenMax = (
    token,
    activatedTokens,
    annualAmortization,
) => {
    const { type, tokenId } = token;
    const currentTokenSumWithoutToken = activatedTokens
        .filter((el) => el.tokenId !== tokenId)
        .reduce(
            (acc, curr) => Math.ceil(curr.value * PERIOD_DIVIDERS[curr.period.toLowerCase()])
        + acc,
            0,
        );
    const availableValue = Math.round(
        annualAmortization - currentTokenSumWithoutToken,
    );
    const maxIndirect = 6883;
    const indirectMax = availableValue <= maxIndirect ? availableValue : maxIndirect;
    const isTokenIndirect = type === AMORTIZATION_TOKEN_TYPES.indirect;

    return isTokenIndirect ? indirectMax : availableValue;
};

const setTokenValueAmortization = (
    token,
    activatedTokens,
    annualAmortization,
) => (dispatch) => {
    const MIN = 0;
    const yearlyMax = getAmortizationTokenMax(
        token,
        activatedTokens,
        annualAmortization,
    );

    const MAX = yearlyMax / PERIOD_DIVIDERS[token.period.toLowerCase()];
    const amount = token.value || MAX;
    const newValue = clamp(MIN, amount, MAX);
    dispatch({
        type: CHANGE_TOKEN_VALUE_AMORTIZATION,
        payload: newValue,
    });
};

export const changeTokenValueAmortization = (
    deltaY,
    token,
    activatedTokens,
    annualAmortization,
) => (dispatch) => {
    const MIN = 0;
    const yearlyMax = getAmortizationTokenMax(
        token,
        activatedTokens,
        annualAmortization,
    );
    const MAX = yearlyMax / PERIOD_DIVIDERS[token.period.toLowerCase()];

    const getValue = getActiveInputDelta(deltaY);
    const amount = getValue(token.value, APP_CONFIG.INCREMENTS.RATE_AMORTIZATION);
    let newValue = clamp(MIN, amount, MAX);
    if (newValue !== MAX) {
        newValue = roundCeilHundreds(newValue, 100);
    }

    dispatch({
        type: CHANGE_TOKEN_VALUE_AMORTIZATION,
        payload: newValue,
    });
};

const shouldRoundValue = (value, amortization, requiredAmortization) => (
    value === requiredAmortization
    && amortization !== requiredAmortization
    && amortization % 100 !== 0
);

const shouldSetRequiredValueBetweenSteps = (value, amortization, requiredAmortization, deltaY) => (
    ((amortization - requiredAmortization > 0
        && amortization - requiredAmortization < 100
        && requiredAmortization - value > 0
        && requiredAmortization - value < 100 && deltaY > 0)
        || (amortization - requiredAmortization > -100
            && amortization - requiredAmortization < 0
            && value - requiredAmortization > 0
            && value - requiredAmortization < 100
            && deltaY < 0))
    && requiredAmortization !== amortization
    && value !== requiredAmortization
    && amortization !== requiredAmortization
);

export const changeAnnualAmortization = (deltaY, value) => (
    dispatch,
    getState,
) => {
    const MIN = 0;
    const getValue = getActiveInputDelta(deltaY);
    const amount = getValue(value, APP_CONFIG.INCREMENTS.ANNUAL_AMORTIZATION);
    let amortization = Math.max(roundNumber(amount), MIN);
    const { objectPrice, equity, years, activatedTokens } = getState().amortizationData;
    const secondMortgage = getSecondMortgage(objectPrice, equity);
    const currentTokenSumWithoutToken = activatedTokens
        .reduce(
            (acc, curr) => Math.ceil(curr.value * PERIOD_DIVIDERS[curr.period.toLowerCase()])
                + acc,
            0,
        );
    const requiredAmortization = Math.round(secondMortgage / years);
    if (shouldRoundValue(value, amortization, requiredAmortization)) {
        amortization = amortization > value
            ? roundCeilHundreds(value, 100)
            : roundFloorHundreds(value, 100);
    }
    if (shouldSetRequiredValueBetweenSteps(value, amortization, requiredAmortization, deltaY)) {
        amortization = requiredAmortization;
    }

    if (currentTokenSumWithoutToken >= amortization) {
        amortization = currentTokenSumWithoutToken;
    }

    dispatch({
        type: CHANGE_ANNUAL_AMORTIZATION,
        payload: amortization,
    });
};

export const setAnnualAmortization = (value) => ({
    type: CHANGE_ANNUAL_AMORTIZATION,
    payload: value,
});

export const changeCommonTerm = (deltaY, value) => (dispatch) => {
    const MIN = 1;
    const MAX = 15;
    const getValue = getActiveInputDelta(deltaY);
    const amount = getValue(value, APP_CONFIG.INCREMENTS.TERM);
    const term = Math.min(Math.max(roundNumber(amount), MIN), MAX);

    dispatch({
        type: CHANGE_COMMON_TERM,
        payload: term,
    });
};

export const setAccountType = (tokenId, account) => ({
    type: SET_ACCOUNT_TYPE_AMORTIZATION,
    payload: { tokenId, account },
});

const setActiveTokenId = (tokenId) => ({
    type: SET_ACTIVE_TOKEN_ID_AMORTIZATION,
    payload: tokenId,
});

export const setTokenPeriod = (tokenId, period) => (dispatch, getState) => {
    dispatch(setActiveTokenId(tokenId));
    dispatch({
        type: CHANGE_TOKEN_PERIOD_AMORTIZATION,
        payload: {
            tokenId,
            period,
        },
    });

    const { activatedTokens, annualAmortization } = getState().amortizationData;
    const activeToken = activatedTokens.find((token) => token.tokenId === tokenId);

    dispatch(
        setTokenValueAmortization(activeToken, activatedTokens, annualAmortization),
    );
};

export const setTokenType = (tokenId, type) => (dispatch) => {
    dispatch({
        type: SET_TOKEN_TYPE_AMORTIZATION,
        payload: {
            tokenId,
            type,
        },
    });
    dispatch(setTokenPeriod(tokenId, PERIOD_OPTIONS[0]));
    if (type === AMORTIZATION_TOKEN_TYPES.indirect) {
        dispatch(setAccountType(tokenId, '3A Konto'));
    }
};

export const activateInput = (tokenId, input) => (dispatch, getState) => {
    const { activeTokenId } = getState().amortizationData;
    if (activeTokenId !== tokenId) {
        dispatch({
            type: RESET_INPUTS_AMORTIZATION,
        });
    }
    const inputToActivate = input;
    dispatch(setActiveTokenId(tokenId));
    dispatch({
        type: ACTIVATE_INPUT_AMORTIZATION,
        payload: inputToActivate,
    });
};

export const activateInputElement = (name) => ({
    type: ACTIVATE_INPUT_AMORTIZATION,
    payload: name,
});

export const initUserValues = () => (dispatch, getState) => {
    const { years } = getState().amortizationData;
    const desiredObjects = localStorageService.getDesiredObjects();

    let desiredObject;
    if (desiredObjects?.length) {
        [desiredObject] = desiredObjects;
    } else {
        desiredObject = null;
    }

    let objectPrice;
    if (desiredObject?.objectPrice) {
        objectPrice = desiredObject.objectPrice;
    } else {
        objectPrice = APP_CONFIG.FINANCE.DEFAULT_VALUES.objectPrice;
    }

    const equity = localStorageService.getEquity() || APP_CONFIG.FINANCE.DEFAULT_VALUES.equity;

    dispatch(setAmortizationEquity(equity));
    dispatch(setAmortizationObjectPrice(objectPrice));
    dispatch(
        setAnnualAmortization(
            roundCeilHundreds(
                Math.round(getSecondMortgage(objectPrice, equity) / years),
                100,
            ),
        ),
    );

    return {
        type: INIT_USER_VALUES_AMORTIZATION,
    };
};

export const changeTokenValue = (deltaY, t) => (dispatch, getState) => {
    const {
        activeInput,
        activeTokenId,
        activatedTokens,
        years,
        annualAmortization,
    } = getState().amortizationData;

    if (!activeInput) {
        return;
    }
    const activeToken = activatedTokens.find(
        (token) => token.tokenId === activeTokenId,
    );

    const handler = {
        [t('RATE').toLowerCase()]: () => {
            dispatch(
                changeTokenValueAmortization(
                    deltaY,
                    activeToken,
                    activatedTokens,
                    annualAmortization,
                ),
            );
        },
        [t('AMORTIZATION').toLowerCase()]: () => {
            dispatch(changeAnnualAmortization(deltaY, annualAmortization));
        },
        [t('TERM').toLowerCase()]: () => {
            dispatch(changeCommonTerm(deltaY, years));
        },
    };
    handler[activeInput]();
};

export const resetTokens = () => ({
    type: RESET_TOKENS_AMORTIZATION,
});

const removeToken = (tokenId) => ({
    type: REMOVE_TOKEN_AMORTIZATION,
    payload: tokenId,
});

export const removeAmortizationToken = (tokenId) => (dispatch) => {
    dispatch(removeToken(tokenId));
};
export const addToken = (token) => ({
    type: ADD_TOKEN_AMORTIZATION,
    payload: token,
});

export const updateTokenPosition = (token) => ({
    type: UPDATE_TOKEN_POSITION_AMORTIZATION,
    payload: token,
});

const moveOutsideToken = (tokenId) => ({
    type: MOVE_OUTSIDE_TOKEN_AMORTIZATION,
    payload: tokenId,
});

export const updateAmortizationTokensEvent = (tokens) => (dispatch, getState) => {
    const trackableTokens = getTrackableTokens(
        tokens,
        APP_CONFIG.LINKS_TO_SHOW_IN_MENU.AMORTIZATION,
    );
    // eslint-disable-next-line no-unused-vars
    const { activatedTokens, nearbyTokens } = getState().amortizationData;
    const appIds = [];
    activatedTokens.forEach((el) => {
        appIds.push(el.tokenId);
    });
    nearbyTokens.forEach((el) => {
        appIds.push(el.tokenId);
    });

    const IdsToUpdate = trackableTokens.map((el) => el && el.tokenId).filter((el) => el);
    trackableTokens.forEach((token) => {
        if (!token || !(token && token.tokenId)) {
            return;
        }
        if (!appIds.includes(token.tokenId)) {
            dispatch(addToken(token));
        }
        if (appIds.includes(token.tokenId)) {
            dispatch(updateTokenPosition(token));
        }
    });

    appIds.forEach((existedTokenId) => {
        if (!IdsToUpdate.includes(existedTokenId)) {
            dispatch(moveOutsideToken(existedTokenId));
        }
    });
};

window.updateAmortizationTokensEvent = updateAmortizationTokensEvent;

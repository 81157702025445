import React, { Component } from 'react';
import { connect } from 'react-redux';

const mapStateToProps = (state) => ({
    mapLoaded: state.mapData.mapLoaded,
});

function withMap(WrappedComponents) {
    // eslint-disable-next-line react/prefer-stateless-function
    class HOC extends Component {
        render() {
            const { mapLoaded } = this.props;
            if (!mapLoaded) {
                return null;
            }
            return (
                <WrappedComponents
                    google={window.google}
                    map={window.globalMapReference}
                    {...this.props}
                />
            );
        }
    }
    return connect(mapStateToProps)(HOC);
}


export default withMap;

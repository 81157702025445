import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import style from './userDataPanel.css';
import { setMapType } from '../redux/actions/map.actions';
import userIcon from '../images/personal-list-icon.svg';

function UserDataPanel(
    { income, equity, expenditure, objectPrice, objectLocation, street, isMapScaled, postcode },
) {
    const { i18n, t } = useTranslation();

    return (
        <div className={cs(style.userDataPanel, {
            [style.userDataPanelSmallMask]: isMapScaled })}
        >
            <img className={style.userIcon} src={userIcon} alt="userIcon" />
            <div className={style.panelContainer}>
                <div className={style.title}>{t('FINANCIAL SITUATION')}</div>
                <div className={style.row}>
                    <div className={style.columnTitle}>{t('INCOME')}</div>
                    <div className={style.columnValue}>
                        {income > 0 ? income.toLocaleString(i18n.language) : ''} {income > 0 ? t('CURRENCY') : ''}
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.columnTitle}>{t('EXPENDITURE')}</div>
                    <div className={style.columnValue}>
                        {expenditure > 0 ? expenditure.toLocaleString(i18n.language) : ''} {expenditure > 0 ? t('CURRENCY') : ''}
                    </div>
                </div>
                <div className={style.row}>
                    <div className={style.columnTitle}>{t('EQUITY')}</div>
                    <div className={style.columnValue}>
                        {equity > 0 ? equity.toLocaleString(i18n.language) : ''} {equity > 0 ? t('CURRENCY') : ''}
                    </div>
                </div>

                <div className={style.title}>
                    {t('DESIRED OBJECT')}
                </div>
                <div className={style.row}>
                    <div className={style.columnTitle}>{t('STREET')}</div>
                    <div className={style.columnValue}>{street}</div>
                </div>
                <div className={style.row}>
                    <div className={style.columnTitle}>{t('LOCATION')}</div>
                    <div className={style.columnValue}>{`${!postcode ? '' : `${postcode} `}`}{objectLocation}</div>
                </div>
                <div className={style.row}>
                    <div className={style.columnTitle}>{t('PURCHASE PRICE')}</div>
                    <div className={style.columnValue}>
                        {objectPrice > 0 ? objectPrice.toLocaleString(i18n.language) : ''} {objectPrice > 0 ? t('CURRENCY') : ''}
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    income: state.userData.income,
    expenditure: state.userData.expenditure,
    equity: state.userData.equity,
    objectPrice: state.userData.objectPrice,
    street: state.userData.street,
    objectLocation: state.userData.objectLocation,
    postcode: state.userData.postcode,
});

const mapDispatchToProps = (dispatch) => ({
    onSetMapType: (map, type) => dispatch(setMapType(map, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDataPanel);

import {
    SET_INCOME,
    SET_EQUITY,
    SET_EXPENDITURE,
    SET_OBJECT_PRICE,
    SET_STREET,
    SET_OBJECT_LOCATION,
    SET_POSTCODE,
} from '../constants';

const initialState = {
    expenditure: 0,
    income: 140000,
    equity: 200000,
    objectPrice: 1000000,
    street: APP_CONFIG.MAP.DEFAULT_ADDRESS.street,
    objectLocation: APP_CONFIG.MAP.DEFAULT_ADDRESS.objectLocation,
    postcode: APP_CONFIG.MAP.DEFAULT_ADDRESS.postcode,
};

export default function userData(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_EXPENDITURE:
            return {
                ...state,
                expenditure: payload,
            };
        case SET_OBJECT_PRICE:
            return {
                ...state,
                objectPrice: payload,
            };
        case SET_EQUITY:
            return {
                ...state,
                equity: payload,
            };
        case SET_INCOME:
            return {
                ...state,
                income: payload,
            };
        case SET_STREET:
            return {
                ...state,
                street: payload,
            };
        case SET_OBJECT_LOCATION:
            return {
                ...state,
                objectLocation: payload.objectLocation,
                postcode: payload.postcode,
            };
        case SET_POSTCODE:
            return {
                ...state,
                postcode: payload,
            };
        default:
            return state;
    }
}

import * as React from 'react';

function ScaleIcon({ fillColor, ...props }) {
    return (
        <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
            <title>Scale Icon</title>
            <g fill="none" fillRule="evenodd">
                <path d="M-2-2h24v24H-2z" />
                <g fill="#C6D2D4">
                    <path d="M5.842.716a.781.781 0 00-.782.781v2.237L1.631.358A.781.781 0 10.535 1.47l3.399 3.347H1.68a.781.781 0 100 1.563h4.161a.792.792 0 00.782-.781V1.497a.781.781 0 00-.781-.781zM16.071 14.587h2.254a.781.781 0 100-1.563h-4.161a.792.792 0 00-.782.781v4.103a.781.781 0 101.563 0V15.67l3.43 3.376a.78.78 0 001.104-.008.781.781 0 00-.008-1.105l-3.4-3.347zM6.608 13.655a.793.793 0 00-.766-.631H1.68a.781.781 0 100 1.563h2.254l-3.4 3.347a.781.781 0 101.097 1.113l3.43-3.376v2.236a.781.781 0 101.562 0v-4.102c-.005-.05-.008-.114-.015-.15zM14.164 6.381h4.16a.781.781 0 100-1.563h-2.253l3.4-3.347A.781.781 0 1018.374.358l-3.429 3.376V1.497a.781.781 0 10-1.563 0V5.6c.005.05.007.1.015.15.055.363.397.631.767.631z" />
                    <circle cx={10.003} cy={9.69} r={1} />
                </g>
            </g>
        </svg>
    );
}

export default ScaleIcon;

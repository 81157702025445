import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStyles } from './mapStyles';
import mapStyle from './map.css';
import { mapLoaded, setMapZooming } from '../redux/actions/map.actions';

class Map extends Component {
    constructor(props) {
        super(props);
        this.mapRef = React.createRef();
    }

    componentDidMount() {
        const script = document.createElement('script');
        window.InitMap = this.InitMap;
        script.src = `https://maps.googleapis.com/maps/api/js?key=${APP_CONFIG.GOOGLE_API_KEY}&callback=InitMap&v=quarterly`;
        document.body.append(script);
    }

    InitMap = () => {
        const { onMapLoaded } = this.props;
        // eslint-disable-next-line no-undef
        window.globalMapReference = new google.maps.Map(this.mapRef.current, {
            zoom: APP_CONFIG.MAP.DEFAULT_ZOOM,
            disableDefaultUI: true,
            styles: mapStyles,
            clickableIcons: false,
            draggable: false,
            scrollwheel: false,
            zoomControl: true,
            center: APP_CONFIG.MAP.DEFAULT_CENTER,
        });
        this.registerMapEvents();
        onMapLoaded();
    };

    registerMapEvents() {
        const { onSetMapZooming } = this.props;
        window.globalMapReference.addListener('tilesloaded', () => {
            onSetMapZooming(false);
        });
    }

    render() {
        return (<div className={mapStyle.map} ref={this.mapRef} />);
    }
}

const mapDispatchToProps = (dispatch) => ({
    onMapLoaded: () => dispatch(mapLoaded()),
    onSetMapZooming: (isZooming) => dispatch(setMapZooming(isZooming)),
});

export default connect(null, mapDispatchToProps)(Map);

import { setObjectLocation, setStreet, setObjectPrice } from '../../homeApp/src/redux/actions/map.actions';
import { setExpenditure, setEquity, setIncome, setPostcode } from '../../homeApp/src/redux/actions/userData.actions';
import { updateTokensEvent } from '../../calculators/src/redux/actions/mixMortgage.actions';
import { updateAmortizationTokensEvent } from '../../calculators/src/redux/actions/amortization.actions';


const enventToActionsMap = {
    'shortcut.income': setIncome,
    'shortcut.objectprice': setObjectPrice,
    'shortcut.owncapital': setEquity,
    'shortcut.expenses': setExpenditure,
    'shortcut.city': setObjectLocation,
    'shortcut.street': setStreet,
    'shortcut.postcode': setPostcode,
    'shortcut.mortgage.tranche.tokens': () => {},
};

function addElectronListener(reduxStore = null) {
    if (window.require) {
        const { ipcRenderer } = window.require('electron');

        if (reduxStore) {
            const messageHandlers = Object.keys(enventToActionsMap);
            messageHandlers.forEach((messageHandler) => {
                ipcRenderer.on(messageHandler, (e, message) => {
                    if (messageHandler === 'shortcut.mortgage.tranche.tokens') {
                        reduxStore.dispatch(updateAmortizationTokensEvent(message));
                        reduxStore.dispatch(updateTokensEvent(message));
                        return;
                    }
                    reduxStore.dispatch(enventToActionsMap[messageHandler](message));
                });
            });
        }
    }
}

export default addElectronListener;

import { useCallback, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';

export const WindowsStateName = {
    HomeApp: 'homeApp',
    Affordability: 'affordability',
    Mixes: 'mixes',
    Amortizations: 'amortizations',
};

const initialWindowsState = {
    [WindowsStateName.HomeApp]: {},
    [WindowsStateName.Affordability]: [],
    [WindowsStateName.Mixes]: [],
    [WindowsStateName.Amortizations]: [],
};

export const useWindowsState = (stateName, state = {}) => {
    const [windowsState, setWindowsState] = useState(initialWindowsState);

    useEffect(() => {
        if (window.require) {
            const { ipcRenderer } = window.require('electron');
            ipcRenderer.send('getWindowsState');
            ipcRenderer.on('getWindowsStateReply', (event, actualState) => {
                if (actualState && Object.keys(actualState).length) {
                    setWindowsState(actualState);
                }
            });
        }
    }, []);

    const sendWindowsState = useCallback((data = state) => {
        if (window.require) {
            const { ipcRenderer, remote } = window.require('electron');
            ipcRenderer.send('getWindowsState');
            const listener = (event, actualState) => {
                if (actualState && !Object.keys(actualState).length) {
                    return;
                }
                let updatedWindowsState;
                if (stateName === WindowsStateName.HomeApp) {
                    updatedWindowsState = { ...actualState, [stateName]: data };
                } else {
                    const windowId = remote.getCurrentWebContents().id;
                    updatedWindowsState = {
                        ...actualState,
                        // eslint-disable-next-line max-len
                        [stateName]: actualState[stateName].some((windowState) => windowState.windowId === windowId) ? (
                            // eslint-disable-next-line max-len
                            actualState[stateName].map((windowState) => (windowState.windowId === windowId ? { ...windowState, screenshots: [...windowState.screenshots, data] } : windowState))
                        ) : (
                            [...actualState[stateName], { windowId, screenshots: [data] }]
                        ),
                    };
                }
                setWindowsState(updatedWindowsState);
                ipcRenderer.send('setWindowsState', updatedWindowsState);
                ipcRenderer.removeListener('getWindowsStateReply', listener);
            };
            ipcRenderer.on('getWindowsStateReply', listener);
        }
    }, [state, stateName]);

    const takeScreenshot = useCallback((elementId) => {
        const input = document.getElementById(elementId);
        html2canvas(input, { scale: 1 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            sendWindowsState(imgData);
            // remove DOM node after screenshot
            input.remove();
        });
    }, [sendWindowsState]);

    return { windowsState, sendWindowsState, takeScreenshot };
};

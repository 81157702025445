import { SET_EXPENDITURE, SET_EQUITY, SET_INCOME, SET_POSTCODE } from '../constants';
import { localStorageService } from '../../../../utils/localStorageService';

export const setExpenditure = (expenditure) => {
    localStorageService.setExpenditure(expenditure);
    return ({
        type: SET_EXPENDITURE,
        payload: expenditure,
    });
};

export const setEquity = (equity) => {
    localStorageService.setEquity(equity);
    return ({
        type: SET_EQUITY,
        payload: equity,
    });
};

export const setPostcode = (postcode) => {
    localStorageService.setPostcode(postcode);
    return ({
        type: SET_POSTCODE,
        payload: postcode,
    });
};

export const setIncome = (income) => {
    const incomeObjects = localStorageService.getIncomeItems();
    let updatedIncomeObject = { income };
    if (incomeObjects) {
        const [incomeObject] = incomeObjects;
        updatedIncomeObject = {
            ...incomeObject,
            ...updatedIncomeObject,
        };
    }
    localStorageService.setIncomeItems([updatedIncomeObject]);
    return ({
        type: SET_INCOME,
        payload: income,
    });
};

import { useEffect } from 'react';
import PropTypes from 'prop-types';
import withMap from '../../hocs/withMap';

const eventToMapEvents = {
    click: 'OnClick',
    dragend: 'OnDragEnd',
    mousedown: 'OnMouseDown',
    mouseout: 'OnMouseOut',
};

const handleEvent = (marker, props, event) => (e) => {
    const eventName = eventToMapEvents[event];
    if (props[eventName]) {
        props[eventName](marker, e);
    }
};

const Marker = ({
    google,
    map,
    icon,
    position,
    ...props
}) => {
    if (!map) {
        return;
    }
    let marker = null;

    useEffect(() => {
        const params = {
            google,
            map,
            position,
            icon,
        };
        if (marker) {
            marker.setMap(null);
        }
        marker = new google.maps.Marker(params);

        Object.keys(eventToMapEvents).forEach((e) => {
            marker.addListener(e, handleEvent(marker, props, e));
        });

        return () => {
            if (marker) {
                marker.setMap(null);
            }
        };
    }, [position.lat, position.lng, map]);
    // eslint-disable-next-line consistent-return
    return null;
};

Marker.defaultProps = {
    suppressUndo: true,
    geodesic: false,
    position: { lat: 40, lng: 8 },
};

Marker.propTypes = {
    map: PropTypes.object,
    google: PropTypes.object,
    icon: PropTypes.string,
    position: PropTypes.object,
    OnClick: PropTypes.func,
    OnMouseDown: PropTypes.func,
    OnMouseOut: PropTypes.func,
};

export default withMap(Marker);

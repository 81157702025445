import React from 'react';
// import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import cs from 'classnames';
import { connect } from 'react-redux';
import { getHorizontalPosition } from '../../../shared/components/radialMenu/helpers';
import style from './mapControls.css';
import MapIcon from '../icons/mapIcon';
import SateliteIcon from '../icons/sateliteIcon';
import ScaleIcon from '../icons/scaleIcon';
import withMap from '../hocs/withMap';
import { setMapType } from '../redux/actions/map.actions';

const circleRadius = 910;
const visibleWidth = 50;
const borderOffset = 3;

function MapControls({ isMapScaled, scaleMap, height, topOffset, map, onSetMapType, mapType }) {
    const { t } = useTranslation();

    return (
        <div
            className={cs(style.menuContainer, {
                [style.menuContainerClosed]: isMapScaled,
            })}
            style={{ height }}
        >
            <div className={style.circleWrapper} style={{ width: visibleWidth, height }}>
                <div
                    className={style.circleLine}
                    style={{
                        width: circleRadius * 2,
                        height: circleRadius * 2,
                        left: visibleWidth - circleRadius * 2 - borderOffset,
                        top: -circleRadius + height / 2 + 10 - borderOffset,
                    }}
                />
            </div>
            <nav className={style.buttonsWrapper}>
                <div>
                    <div
                        className={style.menuButtons}
                        style={{
                            top: topOffset + 20,
                            left: getHorizontalPosition(
                                topOffset,
                                height + 20,
                                circleRadius,
                                visibleWidth,
                            ),
                        }}
                    >
                        <button
                            className={style.menuItem}
                            type="button"
                            onClick={() => {
                                scaleMap(!isMapScaled);
                            }}
                        >
                            <div className={cs(style.controlButton, style.topButton)}>
                                <ScaleIcon fillColor="#C6D2D4" />
                            </div>
                            {!isMapScaled && t('ZOOM OUT MAP')}
                        </button>
                    </div>
                    <div
                        className={style.menuButtons}
                        style={{
                            top: topOffset + 230,
                            left: getHorizontalPosition(
                                topOffset + 230,
                                height,
                                circleRadius,
                                visibleWidth,
                            ),
                        }}
                    >
                        <button
                            className={style.menuItem}
                            type="button"
                            onClick={() => {
                                onSetMapType(map, 'satellite');
                            }}
                        >
                            <div className={cs(style.controlButton, {
                                [style.activeButton]: mapType === 'satellite',
                            })}
                            >
                                <SateliteIcon fillColor={mapType === 'satellite' ? '#72E5D7' : '#C6D2D4'} />
                            </div>
                            {t('SATELITE VIEW')}
                        </button>
                    </div>
                    <div
                        className={style.menuButtons}
                        style={{
                            top: topOffset + 320,
                            left: getHorizontalPosition(
                                topOffset + 320,
                                height,
                                circleRadius,
                                visibleWidth,
                            ),
                        }}
                    >
                        <button
                            className={style.menuItem}
                            type="button"
                            onClick={() => {
                                onSetMapType(map, 'roadmap');
                            }}
                        >
                            <div className={cs(style.controlButton, {
                                [style.activeButton]: mapType === 'roadmap',
                            })}
                            >
                                <MapIcon fillColor={mapType === 'roadmap' ? '#72E5D7' : '#C6D2D4'} />
                            </div>
                            {t('MAP VIEW')}
                        </button>
                    </div>
                </div>
            </nav>
        </div>
    );
}

const mapStateToProps = (state) => ({
    mapType: state.mapData.mapType,
});

const mapDispatchToProps = (dispatch) => ({
    onSetMapType: (map, type) => dispatch(setMapType(map, type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withMap(MapControls));

// export default withMap(MapControls);

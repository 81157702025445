import {
    SET_MAP_TYPE,
    MAP_LOADED, SET_MAP_ZOOM, IS_MAP_ZOOMING,
} from '../constants';

const initialState = {
    mapType: 'roadmap',
    mapLoaded: false,
    zoom: APP_CONFIG.MAP.DEFAULT_ZOOM,
    isZooming: false,
};

export default function mapData(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_MAP_TYPE:
            return {
                ...state,
                mapType: payload,
            };
        case SET_MAP_ZOOM:
            return {
                ...state,
                zoom: payload,
            };
        case IS_MAP_ZOOMING:
            return {
                ...state,
                isZooming: payload,
            };
        case MAP_LOADED:
            return {
                ...state,
                mapLoaded: true,
            };
        default:
            return state;
    }
}
